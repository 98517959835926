/* global $ */
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './dashboard.css'
// import { MultiSelect } from 'react-multi-select-component'
// import MultiSelect from 'react-multi-select-component'

import { DateRangePicker } from 'react-date-range'

import React, { Component } from 'react'

// import { Link } from 'react-router-dom'

const { apiget, apipost } = global.rootRequire('classes/request')
const { AdminBlock } = global.rootRequire('components/shares/rxTemplates')
const { MainTable } = global.rootRequire('components/shares/rxTemplates')

const ulti = require('./dashboardUlti').default

const rxu = global.rxu

class DashboardComponent extends Component {
  constructor (props) {
    super(props)
    
    this.toDate = new Date()

    this.toDate1Ago = new Date()
    this.toDate1Ago.setDate(this.toDate1Ago.getDate() - 1)

    this.toDate3Ago = new Date()
    this.toDate3Ago.setDate(this.toDate3Ago.getDate() - 3)

    this.toDate7Ago = new Date()
    this.toDate7Ago.setDate(this.toDate7Ago.getDate() - 7)

    this.toDate15Ago = new Date()
    this.toDate15Ago.setDate(this.toDate15Ago.getDate() - 15)

    this.toDate30Ago = new Date()
    this.toDate30Ago.setDate(this.toDate30Ago.getDate() - 30)

    this.toDate90Ago = new Date()
    this.toDate90Ago.setDate(this.toDate30Ago.getDate() - 90)

    this.typeDashboard = [{ _id: 0, name: 'Default', api: 'api_game_dashboard' }, { _id: 1, name: 'Custom', api: 'api_game_dashboard_custom' }]
    this.state = {
      paging: { st_col: 'rev', st_type: -1, pg_page: 1, pg_size: 10 },
      data: [],
      dataOverallToday: [],
      dataOverallYesterday: [],
      id: rxu.get(this.props, 'match.params.id'),
      selectionRange: {
        startDate: this.toDate7Ago,
        endDate: this.toDate1Ago,
        key: 'selection'
      },
      selectedNation: [],
      api_dashboard: 'api_game_dashboard',
      is_active: 0,
      flags: {},
      loading: false,
      activeFillter: true,
      editingData: {}
    }
  }

  run (name, params) {
    if (params) { params.inthis = this } else { params = this }
    if (typeof this.props[name] !== 'undefined' && typeof this.props[name] === 'function') {
      return this.props[name](params)
    } else if (typeof this[name] !== 'undefined' && typeof this[name] === 'function') {
      return this[name]()
    }
  }

  toggle (flagname) {
    const flags = this.state.flags
    flags[flagname] = flags[flagname] ? 0 : 1
    this.setState({ flags: flags })
  }

  flag (flagname) {
    return rxu.get(this.state, ['flags', flagname], 0)
  }

  handleSelect (ranges) {
    this.clearCanvasDom()

    // if (ranges && ranges.selection) {
    //   if (ranges.selection.startDate !== ranges.selection.endDate) {
    //     ranges.selection.startDate.setDate(ranges.selection.startDate.getDate() - 1)
    //   }
    // }
    
    this.setState({
      selectionRange: ranges.selection
    }, () => {
      this.fetchData()
      this.fetchDetail()
    })
  }

  sortByField (array, field, reverse = false) {
    return array.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
  
      if (valueA < valueB) return reverse ? 1 : -1;
      if (valueA > valueB) return reverse ? -1 : 1;
      return 0;
    });
  }

  onClickSort (e, stcol) {
    if (stcol === 'organic') {
      const paging = this.state.paging
      paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
      paging.st_col = stcol
      this.setState({ paging: paging }, () => {
        let sortType  = (paging.st_type === 1) ? false : true
        let newArrToday = this.sortByField(this.state.dataOverallToday, 'organic', sortType) 
        this.setState({ dataOverallToday: newArrToday })
      })
    } else {
      const paging = this.state.paging
      paging.st_type = (paging.st_col !== stcol) ? -1 : (-1 * (paging.st_type))
      paging.st_col = stcol
      this.setState({ paging: paging }, () => { this.fetchDetail() })
    }
  }

  onBlurDatafilter (e, name) {
    clearTimeout(this.timerDatafilter)
    const paging = this.state.paging
    paging['search_' + name] = e.target.value
    this.setState({ paging: paging })

    this.timerDatafilter = setTimeout((e, name) => {
      this.fetchDetail()
    }, 500)
  }

  onBlurDatafilterName (e, val) {
    this.clearCanvasDom()
    const activeFillter = this.state.activeFillter
    const selectedNation = this.state.selectedNation
    if (activeFillter) {
      selectedNation.push(val)
      this.setState({ selectedNation: selectedNation, activeFillter: !activeFillter }, (e) => { this.fetchDetail(); this.fetchData() })
    } else {
      selectedNation.pop(val)
      this.setState({ selectedNation: selectedNation, activeFillter: !activeFillter }, (e) => { this.fetchDetail(); this.fetchData() })
    }
  }

  helpSortClass (stcol, extraclass) {
    extraclass = extraclass || []
    let result = ''
    if (this.state.paging.st_col === stcol) {
      result = this.state.paging.st_type === 1 ? 'rx-sort-asc' : 'rx-sort-desc'
    }
    for (let i = 0; i < extraclass.length; i++) { result += ' ' + extraclass[i] }
    return result
  }

  componentDidMount () {
    if ($(window).width() > 1000) {
      this.isFullview = true
      $('.beside').addClass('rxsideactive')
      $('.bemain').addClass('rxsideactive')
      $('.adtopbar__togglesidebar').addClass('rxsideactive')
      $('.adtopbar__logo').addClass('rxsideactive')
    }
    this.fetchData()
    this.fetchDetail()
    this.fetchDataInfoGame()
  }

  componentWillUnmount () {
    if ($(window).width() > 1000 && this.isFullview) {
      $('.beside').removeClass('rxsideactive')
      $('.bemain').removeClass('rxsideactive')
      $('.adtopbar__togglesidebar').removeClass('rxsideactive')
      $('.adtopbar__logo').removeClass('rxsideactive')
    }
  }

  static getDerivedStateFromProps (props, state) {
    const id = rxu.get(props, 'match.params.id')
    const idOld = state.id

    const navigated = id !== idOld
    if (navigated) {
      return { idOld, id }
    }
    return null
  }

  fetchData () {
    this.setState({ loading: true }, () => {
      const api_dashboard = this.state.api_dashboard
      apiget(rxu.get(rxu.config, api_dashboard), {
        ...this.state.paging,
        id: this.state.id,
        startDate: this.state.selectionRange.startDate,
        endDate: this.state.selectionRange.endDate,
        nations: rxu.array(this.state.selectedNation.map(e => e.value ? e.value : e)).join(',')
      }, {
        1: (json) => {
          this.setState({ data: json.data, loading: false }, () => { this.initCharAll(json.data.data) })
        }
      })
    })
  }

  fetchDetail () {
    apiget(global.rxu.config.api_game_detail, {
      ...this.state.paging,
      id: this.state.id,
      startDate: this.state.selectionRange.startDate,
      endDate: this.state.selectionRange.endDate,
      nations: rxu.array(this.state.selectedNation.map(e => e.value ? e.value : e)).join(',')
    }, {
      1: (json) => {
        this.setState({ dataOverallToday: json.data.data, dataOverallYesterday: json.data.dataago })
      }
    })
  }

  initCharAll (data) {
    $('#chartShow').html('')
    for (const i in data) {
      // this.clearCanvasDom(i)
      const curData = data[i]
      this.initChart(i, curData)
    }
  }

  initCharBlock (item, index, color) {
    return {
      lineTension: 0,
      data: item,
      label: index,
      backgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      borderColor: color,
      borderWidth: 2.5,
      pointBackgroundColor: global.rxu.strRgbaAddAlpha(color, 1),
      pointBorderWidth: 1 // pointStyle: 'circle'
    }
  }

  initCharOption (options) {
    return {
      elements: {
        point: {
          radius: 2,
          zIndex: 100
        }
      },
      maintainAspectRatio: false,
      title: { display: false },
      tooltips: {
        position: 'nearest',
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItem, chart) {
            var dataLabel = `${chart.datasets[tooltipItem.datasetIndex].label}` || ''
            const returnLabel = options?.format ? (dataLabel + ': ' + ulti.number_format_(tooltipItem.yLabel, 2)) : (dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2))

            // return returnLabel // dataLabel + ':' + ulti.number_format(tooltipItem.yLabel, 2);

            var corporation = chart.datasets[tooltipItem.datasetIndex].label
            var valor = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]

            // Loop through all datasets to get the actual total of the index
            var total = 0
            for (var i = 0; i < chart.datasets.length; i++) { total += chart.datasets[i].data[tooltipItem.index] }

            // If it is not the last dataset, you display it as you usually do
            if (tooltipItem.datasetIndex !== chart.datasets.length - 1) {
              return returnLabel
            } else {
              return options?.format ? [corporation + ': ' + ulti.number_format_(valor, 2)] : options?.hasTotal ? [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)] : [corporation + ': ' + ulti.number_format(valor, 2)]
              // return options?.format ? [corporation + ': ' + ulti.number_format_int(valor, 2)] : [corporation + ': ' + ulti.number_format(valor, 2), 'Total : ' + ulti.number_format(total, 2)]
            }
          }
          // label: function(tooltipItem, chart) {
          //     var dataLabel = chart.datasets[tooltipItem.datasetIndex].label || ''
          //     let returnLabel = options?.format ? dataLabel + ': ' + ulti.number_format_int(tooltipItem.yLabel, 2) : dataLabel + ': ' + ulti.number_format(tooltipItem.yLabel, 2)
          //     return returnLabel // dataLabel + ':' + ulti.number_format(tooltipItem.yLabel, 2);
          // }
        }
      },
      legend: {
        display: true,
        position: 'top',
        labels: { pointBorderWidth: 0, boxWidth: 2, boxHeight: 2, marginBottom: 0, fontSize: 12, usePointStyle: false, marginRight: 15 },
        onClick: function (e, legendItem) {
          var index = legendItem.datasetIndex
          var ci = this.chart
          var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
          ci.data.datasets.forEach(function (e, i) {
            var meta = ci.getDatasetMeta(i)
            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null
              } else if (meta.hidden === null) {
                meta.hidden = true
              }
            } else if (i === index) {
              meta.hidden = null
            }
          })
          ci.update()
        }
      },

      hover: { mode: 'nearest', intersect: true },
      interaction: { mode: 'nearest', intersect: true },
      scales: {
        xAxes: [{ ticks: { fontColor: 'rgba(150,150,150,0.7)', minRotation: 30, autoSkip: true, maxTicksLimit: 15 }, display: true, gridLines: { display: false }, stacked: options.stacked || false }],
        yAxes: [{ ticks: { callback: function (value) { return ulti.number_format(value) }, fontColor: 'rgba(0,0,0,0.8)', min: options.min || 0, autoSkip: true, maxTicksLimit: 10 }, display: true, gridLines: { display: true, color: 'rgba(150,150,200,0.2)', lineWidth: 0.5 }, stacked: options.stacked || false }]
      }
    }
  }

  initChart (id, data) {
    // Simple cook data
    const options = this.initCharOption(data.options || {})
    const chartMap = {}
    const chartData = data.data
    const labelArray = data.label
    const chartOptions = data.options || {}
    if (typeof chartData.forEach !== 'undefined') {
      chartData.forEach((item, index) => {
        chartMap[item.label] = item.data
      })
    }

    // Makeup dataset
    const chartDataset = []
    Object.keys(chartMap).forEach((index) => {
      const item = chartMap[index]
      const currentColor = ulti.strToRgb(index || '')
      const currentFilltype = (chartOptions.fill ? { fill: chartOptions.fill } : chartOptions.type === 'line' ? { fill: false } : { fill: true })
      chartDataset.push({ ...this.initCharBlock(item, index, currentColor), ...currentFilltype })
    })

    const labelId = `chart_${id}`

    const chartContainer = document.getElementById(labelId)

    if (typeof (this[labelId]) !== 'undefined') { 
      this.releaseCanvas(chartContainer)
      this[labelId].destroy()
      chartContainer.remove()
    }

    let strChart = `
    <div class='${chartOptions.class}'>
      <div class='chart__item'>
        <div class='chart__title'> ${data.name} </div>
        <div class='chart__content'><canvas id='${labelId}' width='600' height='300' /></div>
      </div>
    </div>`

    if (data.name === 'COST') {
      strChart = `
        <div class='${chartOptions.class}'>
          <div class='chart__item'>
            <div class='chart__title'> ${data.name} </div>
            <div class='chart__alert'>Từ tháng 11/2022 Cost GG + thêm 5%</div>
            <div class='chart__content'><canvas id='${labelId}' width='600' height='300' /></div>
          </div>
        </div>`
    }
    
    $('#chartShow').append(strChart).ready(function () {
      const ctx = document.getElementById(labelId).getContext('2d')

      let chartType = chartOptions.type || 'line'
      chartType = (chartType === 'line') ? 'LineWithLine' : chartType

      const config = {
        type: chartType,
        options: options,
        hover: { mode: 'nearest', intersect: true },
        data: { labels: labelArray, datasets: chartDataset }
      }
      this[labelId] = new global.Chart(ctx, config)
    })
  }

  clearCanvasDom(i) {
    for (let i = 0; i < 8; i++) {
      let labelId = `chart_${i}`
      this.releaseCanvas(labelId)
      if (typeof (this[labelId]) !== 'undefined') { 
        this[labelId].destroy()
      }
    }
  }

  backToGame(e) {
    this.clearCanvasDom()
    window.history.back()
  }

  releaseCanvas(labelId) {
    let canvas = document.getElementById(labelId)
    if (canvas) {
      canvas.width = 1
      canvas.height = 1
      const ctx = canvas.getContext('2d')
      ctx && ctx.clearRect(0, 0, 1, 1)
    }
  }

  handleNationChange (event) {
    this.clearCanvasDom()
    
    this.setState({ selectedNation: event }, () => {
      this.fetchData()
      this.fetchDetail()
    })
  }

  handleTypeDashboadChange (event) {
    this.clearCanvasDom()
    let api_dashboard = this.state.api_dashboard
    api_dashboard = event === 0 ? 'api_game_dashboard' : 'api_game_dashboard_custom'
    this.setState({ api_dashboard: api_dashboard, is_active: event }, () => {
      this.fetchData()
    })
  }

  fetchDataInfoGame () {
    apiget(rxu.get(rxu.config, 'api_game_info'), { searchin__id: this.state.id }, {
      1: (json) => {
        this.setState({ editingData: json.data[0] })
      }
    })
  }

  onBlurData (e) {
    const editingData = this.state.editingData
    editingData.desc = e.target.value
    this.setState({ editingData: editingData })
  }

  handleUpdate () {
    const editingData = this.state.editingData
    editingData._id = this.state.id
    apipost(rxu.get(rxu.config, 'api_game') + '/edit', editingData, {
      1: (json) => { this.fetchDataInfoGame(true) }
    })
  }

  renderTable (vm) {
    return <MainTable><thead>{this.renderTableHead()}{this.renderTableHeadSearch()}</thead><tbody>{this.renderTableBody()}</tbody><tfoot>{this.renderTableFooterOverall()}</tfoot></MainTable>
  }

  renderTableHead () {
    return <tr>
      <th className='rxwidth--100'>STT</th>
      <th className={this.helpSortClass('country', ['rxwidth--100'])} onClick={(e) => this.onClickSort(e, 'country')}>CountryID / Country Name</th>
      <th className={this.helpSortClass('profit', ['rxright'])} onClick={(e) => this.onClickSort(e, 'profit')}>Profit</th>
      <th className={this.helpSortClass('rev', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rev')}>Revenue Total</th>
      <th className={this.helpSortClass('rev', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rev')}>Revenue</th>
      <th className={this.helpSortClass('rev_iap', ['rxright'])} onClick={(e) => this.onClickSort(e, 'rev_iap')}>Revenue IAP</th>
      <th className='rxright'>Cost/Rev</th>
      <th className={this.helpSortClass('cost', ['rxright'])} onClick={(e) => this.onClickSort(e, 'cost')}>Cost</th>
      <th className={this.helpSortClass('rr1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'a1')}>ARPU</th>
      <th className={this.helpSortClass('cost', ['rxright'])} onClick={(e) => this.onClickSort(e, 'cost')}>CPI</th>
      <th className={this.helpSortClass('cost', ['rxright'])} onClick={(e) => this.onClickSort(e, 'conversions_gg')}>Conversion</th>
      <th className={this.helpSortClass('a1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'a1')}>DAU</th>
      <th className={this.helpSortClass('n1', ['rxright'])} onClick={(e) => this.onClickSort(e, 'n1')}>NRU</th>
      <th className={this.helpSortClass('organic', ['rxright'])} onClick={(e) => this.onClickSort(e, 'organic')}>Organic</th>
      <th className={this.helpSortClass('roi', ['rxright'])} onClick={(e) => this.onClickSort(e, 'roi')}>ROI</th>
    </tr>
  }

  renderTableHeadSearch () {
    return <tr className='table-sm'>
      <th className='' />
      <th className=''><input className='form-control form-control-sm' onKeyUp={(e) => this.onBlurDatafilter(e, 'country')} /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
      <th className=''><input className='form-control form-control-sm' /></th>
    </tr>
  }

  renderTableBody () {
    const dataDetail = this.state.dataOverallToday
    const dataDetailYesterday = this.state.dataOverallYesterday

    return global.rxu.array(dataDetail).map((perdata, i) => {
      let perDau = 0; let perRev = 0; let perArpu = 0; let perCpi = 0; let perCost = 0; let perPL = 0; let perR1 = 0; let perConv = 0; let perNru = 0; let perRevIap = 0; let perRevNormal = 0; let perOrganic = 0;
      if (dataDetailYesterday && dataDetailYesterday.length > 0) {
        const dataAgo = dataDetailYesterday.filter(x => x.country === perdata.country)
        perR1 = ulti.number_format_((perdata?.rr1 - dataAgo[0]?.rr1) / dataAgo[0]?.rr1 * 100) 
        perRev = ulti.number_format_((perdata?.rev - dataAgo[0]?.rev) / dataAgo[0]?.rev * 100)
        perRevIap = ulti.number_format_((perdata?.rev_iap - dataAgo[0]?.rev_iap) / dataAgo[0]?.rev_iap * 100)
        perRevNormal = ulti.number_format_(((perdata?.rev - perdata?.rev_iap) - (dataAgo[0]?.rev - dataAgo[0]?.rev_iap)) / (dataAgo[0]?.rev - dataAgo[0]?.rev_iap) * 100)
        const arpu = perdata?.rev / perdata?.a1
        // const arpuAgo = dataAgo[0]?.rev / dataAgo[0]?.a1
        perArpu = ulti.number_format_((arpu - dataAgo[0]) / dataAgo[0] * 100)

        const cpi = perdata?.cost / perdata.conversions_gg
        const cpiAgo = dataAgo[0]?.cost / dataAgo[0]?.conversions_gg
        perCpi = ulti.number_format_((cpi - cpiAgo) / cpiAgo * 100)

        perCost = ulti.number_format_(((perdata?.cost / 23000) - (dataAgo[0]?.cost / 23000)) / (dataAgo[0]?.cost / 23000) * 100)
        const profit = perdata?.rev - perdata?.cost / 23000
        const profitAgo = dataAgo[0]?.rev - dataAgo[0]?.cost / 23000

        perPL = ulti.number_format_((profit - profitAgo) / profitAgo * 100)
        perDau = ulti.number_format_((perdata?.a1 - dataAgo[0]?.a1) / dataAgo[0]?.a1 * 100)
        perNru = ulti.number_format_((perdata?.n1 - dataAgo[0]?.n1) / dataAgo[0]?.n1 * 100)
        perConv = ulti.number_format_((perdata?.conversions_gg - dataAgo[0]?.conversions_gg) / dataAgo[0]?.conversions_gg * 100)

        const organicAgo = dataAgo[0]?.n1 - dataAgo[0]?.conversions_gg
        const organicCurrent = perdata.n1 - perdata.conversions_gg
        perOrganic = (organicAgo > 0) ? ulti.number_format_((organicCurrent - organicAgo) / organicAgo * 100) : 0
      }

      perdata.organic = perdata.n1 - perdata.conversions_gg
      perdata.roi = (!isNaN(perdata.cost) && Number(perdata.cost) > 0) ? (perdata.rev / (perdata.cost / 23000)) * 100 : 0 

      perdata.costrev = (!isNaN(perdata.rev) && Number(perdata.rev) > 0) ? ((perdata.cost / 23000) / perdata.rev) * 100 : 0 
      return <tr className='' key={perdata.country}>
        <td>{i}</td>
        <td className='onclickFilter' onClick={(e) => this.onBlurDatafilterName(e, perdata.country, this.state.activeFillter)}>{perdata.country}</td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.rev - perdata.cost / 23000)}</small><br /><small className={perPL < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perPL) ? perPL : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.rev)}</small><br /><small className={perRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perRev) ? perRev : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.rev - perdata.rev_iap)}</small><br /><small className={perRevNormal < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perRevNormal) ? perRevNormal : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{(perdata.rev_iap === 0) ? '0' : ulti.number_format_int(perdata.rev_iap)}</small><br /><small className={perRevIap < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perRevIap) ? perRevIap : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ Math.round(perdata.costrev * 10) / 10}</small><br /><small className={perdata.costrev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{ Math.round(perdata.costrev * 10) / 10} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.cost / 23000)}</small><br /><small className={perCost < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perCost) ? perCost : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.rev / perdata.a1)}</small><br /><small className={perArpu < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perArpu) ? perArpu : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.cost / perdata.conversions_gg)}</small><br /><small className={perCpi < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perCpi) ? perCpi : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.conversions_gg)}</small><br /><small className={perConv < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perConv) ? perConv : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.a1)}</small><br /><small className={perDau < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perR1) ? perR1 : ''} %</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.n1)}</small><br /><small className={perNru < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perNru) ? perNru : ''} %</small></td>

        <td className='rxcenter'><small className='rxtop'>{(perdata.organic === 0) ? '0' : ulti.number_format_int(perdata.organic)}</small><br /><small className={perOrganic < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perOrganic) && `${perOrganic} %`}</small></td>
        <td className='rxcenter'><small className='rxtop'>{ulti.number_format_int(perdata.roi)}</small><br /><small className={perdata.roi < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{ulti.number_format_(!isNaN(perdata.roi) && perdata.roi)} %</small></td>

      </tr>
    })
  }

  renderTableFooterOverall () {
    const dataDetail = this.state.dataOverallToday
    const dataDetailAgo = this.state.dataOverallYesterday
    let totalRev = 0
    let totalCost = 0
    // eslint-disable-next-line
    let totalR1 = 0; let totalA1 = 0; let totalN1 = 0

    let totalRevAgo = 0; let totalCostAgo = 0
    // eslint-disable-next-line
    let totalR1Ago = 0; let totalA1Ago = 0; let totalN1Ago = 0
    // eslint-disable-next-line
    global.rxu.array(dataDetail).map((perdata, i) => {
      totalRev += perdata.rev
      totalCost += perdata.cost
      totalA1 += perdata.a1
      totalN1 += perdata.n1
      totalR1 += perdata.rr1
    })

    // eslint-disable-next-line
    global.rxu.array(dataDetailAgo).map((per, j) => {
      totalRevAgo += per.rev
      totalCostAgo += per.cost
      totalA1Ago += per.a1
      totalN1Ago += per.n1
      totalR1Ago += per.rr1
    })

    const perProfit = ulti.number_format_(((totalRev - totalCost / 23000) - (totalRevAgo - totalCostAgo / 23000)) / (totalRevAgo - totalCostAgo / 23000) * 100)
    const perRev = ulti.number_format_((totalRev - totalRevAgo) / totalRevAgo * 100)
    const perCost = ulti.number_format_((totalCost / 23000 - totalCostAgo / 23000) / totalCost / 23000 * 100)

    return <tr>
      <th className='rxcenter rxwidth--100'>Total</th>
      <th className='rxcenter rxwidth--100'><small className='rxtop' /></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalRev - totalCost / 23000)}</small><br /><small className={perProfit < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perProfit) && perProfit} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalRev)}</small><br /><small className={perRev < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perRev) && perRev} %</small></th>
      <th className='rxcenter rxwidth--100'><small className='rxtop'>{ulti.number_format_(totalCost / 23000)}</small><br /><small className={perCost < 0 ? 'rxbottom rxdown' : 'rxbottom rxup'}>{!isNaN(perCost) && perCost} %</small></th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
      <th className='rxcenter rxwidth--100'> </th>
    </tr>
  }

  // R E N D E R S
  render () {
    return (
      <>
        { this.state.loading && <div className='bg_loading'><div className='rxloading'></div></div> }
        <AdminBlock name={'Dashboard ' + rxu.get(this.state, 'data.name')} desc={'Dashboard ' + rxu.get(this.state, 'data.desc')} exclass='adsticky'>

          <div className='row'>
            <div className='filtersum__block'>
              <div className='showFilterBtn attop icon-magnifier-add' />
              <div className='filtersum__nation'><b>Nation</b> {rxu.array(this.state.selectedNation.map(e => e.value)).join(',') || 'All'}</div>
              <div className='filtersum__date'><b>Date</b> {this.state.selectionRange.startDate.toLocaleDateString('vi-VN')} - {this.state.selectionRange.endDate.toLocaleDateString('vi-VN')} </div>
            </div>
          </div>
          <div className='row' id='chartShow' />
          <div className='showFilterBtn icon-magnifier-add' />
          <div className='showFilter'>
            <div className='filterWrap'>
              <div className='filterLabel'>Type Dashboard</div>
              <div className='tabSelect'>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(0)}>Default</span></div>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 1 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleTypeDashboadChange(1)}>Custom</span></div>
              </div>
              <div className='filterLabel'>Nation</div>
              {/* <MultiSelect options={ulti.countryOptions} value={this.state.selectedNation} onChange={this.handleNationChange.bind(this)} labelledBy='Select' /> */}
              <div className='filterLabel'>Date</div>
              <DateRangePicker
                staticRanges={[
                  {
                    ...staticRangeHandler,
                    label: 'Today',
                    range: () => ({ startDate: this.toDate, endDate: this.toDate })
                  }, {
                    ...staticRangeHandler,
                    label: 'Yesterday',
                    range: () => ({ startDate: this.toDate1Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 3 days',
                    range: () => ({ startDate: this.toDate3Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 7 days',
                    range: () => ({ startDate: this.toDate7Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 15 days',
                    range: () => ({ startDate: this.toDate15Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 30 days',
                    range: () => ({ startDate: this.toDate30Ago, endDate: this.toDate1Ago })
                  }, {
                    ...staticRangeHandler,
                    label: 'Last 90 days',
                    range: () => ({ startDate: this.toDate90Ago, endDate: this.toDate1Ago })
                  }]}
                ranges={[this.state.selectionRange]}
                onChange={this.handleSelect.bind(this)}
              />
            </div>
          </div>

          {/* <Link className='backToGame' to='/admin/game'>X</Link> */}
          <button className='backToGame' onClick={(e) => this.backToGame()}> x </button>
          <div className='noteForGameBtn'>+</div>
          <div className='noteForGame'>
            <div className='filterWrap'>
              <div className='filterLabel'>Note Dashboard</div>
              <textarea className='form-control form-control-sm' value={this.state.editingData?.desc || ''} onChange={(e) => this.onBlurData(e, 'desc')} />
              <div className='tabSelect'>
                <div className='tabSelectLabel'><span className={Number(this.state.is_active) === 0 ? 'tabSelectItem tabSelectItemActive' : 'tabSelectItem'} onClick={(e) => this.handleUpdate(0)}>Update</span></div>
              </div>
            </div>
          </div>
          <div className='rxcol-100'>
            <div className='betable'>
              <div className='adblock__head '>
                <div className='adblock__title'>Bảng summary theo country </div>
                <div className='adblock__desc' />
              </div>
              {this.renderTable()}
            </div>
          </div>

        </AdminBlock>
      </>
    )
  }
}

const staticRangeHandler = {
  range: {},
  isSelected (range) {
    const definedRange = this.range()
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    )
  }
}

function isSameDay (date1, date2) {
  try {
    return (date1.toString() === date2.toString())
  } catch (e) {
    return false
  }
}

export default DashboardComponent
